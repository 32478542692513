import { defineStore } from 'pinia';
import Storage from '@/utils/storage';
import { getPromotionFuncs } from '@/utils'
const vcart = import.meta.env.VITE_CART;

export const useCartStore = defineStore({
	id: 'CartStore',
	state: () => ({
		vat: 0.1,
		shipping: 0,
		cart: Storage.get(vcart, []),
		orders: [],
		picked: {},
		updating: 0, // index + 1 of cart
		beer: Storage.get('beer', 1), // open popup once
		totalVat: 0,
		openTime: true
	}),
	getters: {
		vats: (state) => {
			let result = {};
			let totalVat = 0;

			state.cart.forEach(function (item) {
				let vatRate = item.vatRate;

				const toppingsTotal = item.productToppings.reduce(
					(total, topping) => total + topping.price,
					0
				);
				const saucesTotal = item.productSauces.reduce(
					(total, sauce) => total + sauce.price,
					0
				);

				let itemPrice = item.price + toppingsTotal + saucesTotal;
				let vatPrice = vatRate * itemPrice * item.qty;
				result[vatRate] = (result[vatRate] || 0) + vatPrice;
				totalVat += vatPrice;
			});

			state.totalVat = totalVat;

			return result;
		},
		subTotal: (state) => {
			return state.cart.reduce((sum, item) => {
				const toppingsTotal = item.productToppings.reduce(
					(total, topping) => total + topping.price,
					0
				);
				const saucesTotal = item.productSauces.reduce(
					(total, sauce) => total + sauce.price,
					0
				);
				let productPrice = item.price;
				if (getPromotionFuncs().hasDiscountPercent(item)) {
					productPrice = getPromotionFuncs().getPriceByDiscountPercent(item);
				} else if (getPromotionFuncs().hasSpecialPrice(item)) {
					productPrice = getPromotionFuncs().getSpecialPrice(item);
				}

				return sum + (productPrice + toppingsTotal + saucesTotal) * item.qty;
			}, 0);
		},
		total() {
			if (this.cart && this.cart.length > 0) {
				return this.subTotal + this.totalVat + this.shipping;
			}

			return 0;
		},
	},
	actions: {
		setBeer(val) {
			this.beer = val;
			Storage.set('beer', val); // local storage
		},
		setUpdating(val) {
			this.updating = val;
		},
		setVAT(val) {
			this.vat = val;
		},
		setShipping(val) {
			this.shipping = val;
		},
		setPicked(val) {
			this.picked = val;
		},
		setOrders(val) {
			this.orders = val || [];
		},
		addProduct(cartItem) {
			this.cart.push(cartItem);
			Storage.set(vcart, this.cart); // local storage
		},
		updateProduct(cartItem) {
			this.cart[this.updating - 1] = cartItem;
			Storage.set(vcart, this.cart); // local storage
		},
		removeProduct(index) {
			this.cart.splice(index, 1);
			Storage.set(vcart, this.cart); // local storage
		},
		// create item or update qty
		couProduct(cartItem) {
			const index = this.cart.findIndex((x) => x.productId === cartItem.productId);
			if (index >= 0) {
				this.cart[index].qty = this.cart[index].qty + 1;
			} else {
				this.cart.push(cartItem);
			}
			Storage.set(vcart, this.cart); // local storage
		},
		// delete item or remove qty
		dorProduct(cartItem) {
			const index = this.cart.findIndex((x) => x.productId === cartItem.productId);
			if (index >= 0) {
				if (this.cart[index].qty > 1) {
					this.cart[index].qty = this.cart[index].qty - 1;
				} else this.cart.splice(index, 1);
			}
			Storage.set(vcart, this.cart); // local storage
		},
		setCart(val) {
			this.cart = val;
			Storage.set(vcart, val); // local storage
		},
		resetCart() {
			this.cart = [];
			Storage.set(vcart, []); // local storage
		},
	},
});

// import 'vuetify/styles'; // Ensure you are using css-loader
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import {
	mdiPencil,
	mdiPlus,
	mdiMinus,
	mdiCheckAll,
	mdiCellphone,
	mdiPhone,
	mdiMapMarker,
	mdiImage,
	mdiClose,
	mdiCloudUploadOutline,
	mdiChevronLeft,
	mdiClockTimeEight,
	mdiFileDocument,
	mdiEye,
	mdiEyeOff,
	mdiInformationVariantCircleOutline,
	mdiEmoticonExcitedOutline,
	mdiHome,
	mdiFileDocumentOutline,
	mdiAccount,
	mdiCartOutline,
	mdiAlert,
	mdiCloseCircle,
	mdiStar,
	mdiPizza,
	mdiSendVariant,
	mdiCalendar
} from '@mdi/js';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import i18n from '@/locale';
import locales from '@/locale/vuetify';
import { light, dark } from './vuetifyThemes.js';

const opts = {
	components,
	directives,
	theme: {
		defaultTheme: 'dark',
		themes: {
			light,
			dark,
		},
	},
	icons: {
		defaultSet: 'mdi',
		aliases: {
			...aliases,
			mdiPencil,
			mdiPlus,
			mdiMinus,
			mdiCheckAll,
			mdiCellphone,
			mdiPhone,
			mdiMapMarker,
			mdiImage,
			mdiClose,
			mdiCloudUploadOutline,
			mdiChevronLeft,
			mdiClockTimeEight,
			mdiFileDocument,
			mdiEye,
			mdiEyeOff,
			mdiInformationVariantCircleOutline,
			mdiEmoticonExcitedOutline,
			mdiHome,
			mdiFileDocumentOutline,
			mdiAccount,
			mdiCartOutline,
			mdiAlert,
			mdiCloseCircle,
			mdiStar,
			mdiPizza,
			mdiSendVariant,
			mdiCalendar
		},
		sets: {
			mdi,
		},
	},
	lang: {
		locales,
		current: 'en',
		t: (key, ...params) => i18n.t(key, params),
	},
};

export default createVuetify(opts);

import d2Bounds from './customer_address_bounds.json';

export const deepClone = (val) => {
	return JSON.parse(JSON.stringify(val));
};
export const isMobile = () => {
	const userAgent = navigator.userAgent;
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};
export const parseCurrency = (val) => {
	return String(val || '').replace(/(\d)(?=(\d{3})+\b)/g, '$1.');
};
export const getTextByLang = (item, lang) => {
	return item[lang] || item['en'] || '';
};
/**
 * @param {function} fn
 * @param %{number} wait
 * @returns
 */
export const debounce = function (fn, wait) {
	let t;
	return function () {
		clearTimeout(t);
		t = setTimeout(() => fn.apply(this, arguments), wait);
	};
};

export const throttle = function (fn, delay) {
	delay || (delay = 100);
	var throttle = false;
	return function () {
		if (throttle) {
			return;
		} // you no enter
		throttle = setTimeout(function () {
			// tail it - and do one last ajax request
			fn.apply(this, arguments);
			throttle = false;
		}, delay);
		fn.apply(this, arguments);
	};
};

/**
 * @param %{number} ms
 * @returns
 */
export const sleep = function (ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * @param {*} obj
 * @returns boolean
 */
export const isEmptyArray = (arr) => {
	if (Array.isArray(arr)) {
		if (arr.length === 0) return true;
		else if (arr[0] === null || arr[0] === undefined) return true;
	}
	return false;
};

/**
 * @param {*} obj
 * @returns boolean
 */
export const isEmptyObject = (obj) => {
	return JSON.stringify(obj) === '{}';
};

export const isEmptyInObject = (obj) => {
	if (!obj) return true;
	if (isEmptyObject(obj)) return true;
	for (const key in obj) {
		if (!obj[key]) return true;
		else if (isEmptyArray(obj[key])) {
			return true;
		}
	}
	return false;
};

export const resetErrorObj = (errorObj = {}) => {
	if (errorObj?.constructor !== Object) return {};

	for (let key in errorObj) {
		errorObj[key] = '';
	}

	return { ...errorObj };
};

export const isNumeric = (n) => {
	return !isNaN(parseFloat(n)) && !isNaN(n - 0);
};

/**
 * @param {*} input is File
 * @returns
 */
export const isFile = (input) => {
	if (input instanceof File) return true;
	else return false;
};

/**
 * Parse an object into a flat key-value pair object.
 * @param {string} key - The key of the parse object.
 * @param {*} value - The object, array, or any value to parse.
 * @returns {Object} - The parsed data as a flat key-value pair object.
 */
export const parseForm = (key, value) => {
	const data = {};

	const handleObject = (prefix, obj) => {
		for (const prop in obj) {
			const currentKey = prefix ? `${prefix}[${prop}]` : prop;
			const currentValue = obj[prop];

			if (Array.isArray(currentValue)) {
				handleArray(currentKey, currentValue);
			} else if (isFile(currentValue)) {
				data[currentKey] = currentValue;
			} else if (typeof currentValue === 'object' && currentValue !== null) {
				handleObject(currentKey, currentValue);
			} else {
				if (currentValue !== null && currentValue !== undefined)
					data[currentKey] = currentValue;
			}
		}
	};

	const handleArray = (prefix, arr) => {
		arr.forEach((item, index) => {
			const currentKey = `${prefix}[${index}]`;

			if (Array.isArray(item)) {
				handleArray(currentKey, item);
			} else if (isFile(item)) {
				data[currentKey] = item;
			} else if (typeof item === 'object' && item !== null) {
				handleObject(currentKey, item);
			} else {
				if (item !== null && item !== undefined) data[currentKey] = item;
			}
		});
	};

	try {
		if (typeof value === 'object' && value !== null) {
			if (Array.isArray(value)) {
				handleArray(key, value);
			} else {
				handleObject(key, value);
			}
		} else {
			data[key] = value;
		}
	} catch (err) {
		console.error('An error occurred while parsing the form:', err);
	}

	return data;
};

export const formData = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (Array.isArray(data[key])) {
			if (data[key].length > 0) {
				data[key].forEach((value) => {
					formData.append(`${key}[]`, value.id || value);
				});
			} else {
				formData.append(key, []);
			}
		} else if (typeof data[key] === 'object' && data[key] !== null && data[key].id) {
			formData.append(key, data[key].id);
		} else {
			if (data[key] == null && data[key] == undefined) {
				formData.append(key, '');
			} else {
				formData.append(key, data[key]);
			}
		}
	});
	return formData;
};

/**
 * @param {*} obj
 * @returns string params
 */
export const serialize = function (obj) {
	if (!obj) return '';
	const str = [];
	for (const p in obj) {
		if (Array.isArray(obj[p])) {
			obj[p].forEach((e) => {
				//ex: [{from: 1},{to: 12}] -> urlParams=price[from]=1&price[to]=12
				if (typeof e === 'object') {
					// str.push(encodeURIComponent(p) + '['+d+']=' + encodeURIComponent(e))
					for (let key in e) {
						str.push(
							encodeURIComponent(p) + '[' + key + ']=' + encodeURIComponent(e[key])
						);
					}
				} else {
					str.push(encodeURIComponent(p) + '[]=' + encodeURIComponent(e));
				}
			});
		} else {
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
		}
	}
	return str.join('&');
};

/**
 *
 * @param {*} string
 * @returns word-word
 */
export const kebabCase = (string) =>
	string
		.replace(/([a-z])([A-Z])/g, '$1-$2') // Convert camelCase to kebab-case
		.replace(/[\s_]+/g, '-') // Replace spaces or underscores with hyphens
		.replace(/\//g, '-'); // Replace slashes with hyphens

/**
 * @param {array} xs
 * @param {function} f
 * @returns array
 */
export const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

// DD-MM-YYYY
export const isDate = (date) => {
	return String(date).match(/^(0[1-9]|[12][0-9]|3[01])[- ](0[1-9]|1[012])[- ](19|20)\d\d$/);
};

/**
 * @param {*} val
 * return string currency
 */
export const formatCurrency = (val) => {
	return String(val || '').replace(/(\d)(?=(\d{3})+\b)/g, '$1.');
};

export const toStringUTF8 = (str) => {
	str = str.toLowerCase();
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
	str = str.replace(/đ/g, 'd');
	// Some system encode vietnamese combining accent as individual utf-8 characters
	str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
	str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
	return str;
};

export const mergeObjects = (...objs) => {
	return objs.reduce((prev, obj) => {
		Object.keys(obj).forEach((key) => {
			const pVal = prev[key];
			const oVal = obj[key];
			if (oVal === null || typeof oVal === 'undefined') return;
			if (Array.isArray(oVal) && !oVal.length) return;
			prev[key] =
				pVal && typeof pVal === 'object' && !Array.isArray(pVal)
					? mergeObjects(pVal, oVal)
					: oVal;
		});
		return prev;
	}, {});
};

/**
 *
 * @param {Object|String} error
 * @returns the error array
 */
export const getErrorArr = (error = {}) => {
	const tempObj = error?.data?.errors || { undefined: [error] };

	let errorArr = [];

	//prop is 1 array which contain 1 error message code
	for (let prop in tempObj) {
		errorArr.push(tempObj[prop][0]);
	}

	return errorArr;
};

export const mergeByKey = (target, source, prop) => {
	source.forEach((sourceElement) => {
		let targetElement = target.find((targetElement) => {
			return sourceElement[prop] == targetElement[prop];
		});
		targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
	});
	return target;
};

export const uniqueArrayByKey = (arr, key) => {
	const map = new Map();
	return arr.reduce((result, item) => {
		if (!map.has(item[key])) {
			map.set(item[key], true);
			result.push(item);
		}
		return result;
	}, []);
};

/**
 *
 * @param {*} number
 * @returns 1K , 10K , 100K, 1M ....
 */
export const abbreviateNumber = (number) => {
	if (number === 0) return '';
	const suffixes = ['', 'K', 'M', 'B'];
	const suffixNum = Math.floor(Math.log10(number) / 3);
	const shortNumber = parseFloat(
		(suffixNum != 0 ? number / Math.pow(1000, suffixNum) : number).toPrecision(2)
	);
	const suffix = suffixes[suffixNum];
	return `${shortNumber}${suffix}`;
};

export const isUrl = function (s) {
	var regexp =
		/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
	return regexp.test(s);
};

export const imgExt = '.jpg,.jpeg,.png,.gif,.bmp,.svg,.webp';
export const isImgName = (str) => {
	// Regular expression pattern to match common image file extensions
	const ext = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;
	return ext.test(str);
};

export const urltoFile = function (url, filename, mimeType) {
	return fetch(url)
		.then(function (res) {
			return res.arrayBuffer();
		})
		.then(function (buf) {
			return new File([buf], filename, { type: mimeType });
		});
};

export const compressImage = async function (
	inputDataUri,
	quality = 80,
	outputFormat = 'image/jpeg'
) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = function () {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			// Set canvas dimensions to the same as the image
			canvas.width = img.width;
			canvas.height = img.height;

			// Draw image on canvas
			ctx.drawImage(img, 0, 0);

			// Compress and convert to data URI
			const compressedDataUri = canvas.toDataURL(outputFormat, quality);

			// Resolve the promise with the compressed data URI
			resolve(compressedDataUri);
		};
		img.onerror = function () {
			reject(new Error('Failed to load image'));
		};
		img.src = inputDataUri;
	});
};

export const googleMapInit = (initMap = () => {}) => {
	if (window.google || window.google?.maps) initMap;
	const googleMapsScript = document.createElement('script');
	googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=AIzaSyAAq_3rBXv_JZgDNmNZMuIARuTCkkyf1VY`;
	googleMapsScript.onload = initMap;
	document.head.appendChild(googleMapsScript);
};

// method address string get {lat, lng}
export const googleMapGeo = async (txt) => {
	if (!txt || !window.google || !window.google?.maps) return null;
	return new Promise((resolve, reject) => {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ address: txt }, function (results, status) {
			if (status === 'OK') {
				const location = results[0].geometry.location;
				const output = {
					lat: location.lat(),
					lng: location.lng(),
				};
				resolve(output);
			} else {
				reject(null);
			}
		});
	});
};

export const getWardCoordinates = (wardId) => {
	switch (wardId) {
		case 184:
			return [
				{ lat: 10.801417628689208, lng: 106.7111175042276 },
				{ lat: 10.800195131325584, lng: 106.70723366567701 },
				{ lat: 10.793661009334254, lng: 106.70064616051107 },
				{ lat: 10.791995839398943, lng: 106.70652556251584 },
				{ lat: 10.795536949222154, lng: 106.70995878996389 },
				{ lat: 10.79867754146899, lng: 106.71094584285521 },
			];
		case 185:
			return [
				{ lat: 10.795563457919547, lng: 106.70996122947481 },
				{ lat: 10.79191836378017, lng: 106.7065711655152 },
				{ lat: 10.788678242991304, lng: 106.70825092693661 },
				{ lat: 10.787928210053886, lng: 106.70901445485546 },
				{ lat: 10.787711530548046, lng: 106.71005869002138 },
				{ lat: 10.788164717079558, lng: 106.71232247436993 },
				{ lat: 10.790346326405121, lng: 106.71341681561901 },
				{ lat: 10.792264443264807, lng: 106.71332025609703 },
				{ lat: 10.793402660669573, lng: 106.7093184003529 },
			];
		case 186:
			return [
				{ lat: 10.793531693610769, lng: 106.70939847158772 },
				{ lat: 10.801499091299782, lng: 106.7112652890126 },
				{ lat: 10.80033982699487, lng: 106.71620055346915 },
				{ lat: 10.799075169922732, lng: 106.7160503497683 },
				{ lat: 10.798379606263483, lng: 106.71416207467188 },
				{ lat: 10.79698847411342, lng: 106.71321793712367 },
				{ lat: 10.794353736480428, lng: 106.71360417521157 },
				{ lat: 10.792330242295181, lng: 106.71334668315296 },
				{ lat: 10.793402660669573, lng: 106.7093184003529 },
			];
		case 187:
			return [
				{ lat: 10.800223393004227, lng: 106.71642426911026 },
				{ lat: 10.78804309536149, lng: 106.71275933540272 },
				{ lat: 10.786573026060498, lng: 106.71474450782763 },
				{ lat: 10.789003136740002, lng: 106.72317385535501 },
				{ lat: 10.79308327839687, lng: 106.72586147340722 },
				{ lat: 10.798723383007234, lng: 106.72607526120683 },
				{ lat: 10.79797337519977, lng: 106.72112760070164 },
			];
		case 321:
			return [
				{ lat: 10.799214821936125, lng: 106.72839043301241 },
				{ lat: 10.786146393261763, lng: 106.7215239781163 },
				{ lat: 10.782478699909639, lng: 106.72405598335925 },
				{ lat: 10.783111064022824, lng: 106.72542927433845 },
				{ lat: 10.78070807332184, lng: 106.72680256531768 },
				{ lat: 10.779611965927007, lng: 106.72736046477799 },
				{ lat: 10.77965412397756, lng: 106.72843334835551 },
				{ lat: 10.78045512581587, lng: 106.72937748590371 },
				{ lat: 10.777691511808966, lng: 106.73565595506459 },
				{ lat: 10.778639341472763, lng: 106.74367627892984 },
				{ lat: 10.781068141360773, lng: 106.74738492492767 },
				{ lat: 10.782786061208009, lng: 106.74554567815473 },
				{ lat: 10.784324497061183, lng: 106.74501858586132 },
				{ lat: 10.785271617720976, lng: 106.74417714541778 },
				{ lat: 10.785245787197072, lng: 106.74295004477095 },
				{ lat: 10.786003481642668, lng: 106.74144246397627 },
				{ lat: 10.787348120095517, lng: 106.74095087769876 },
				{ lat: 10.78775770847362, lng: 106.74038757033841 },
				{ lat: 10.788283088361377, lng: 106.73994325899302 },
				{ lat: 10.787954381349898, lng: 106.73931584448094 },
				{ lat: 10.790370508113384, lng: 106.73660633011882 },
				{ lat: 10.790568538698203, lng: 106.7356772396291 },
				{ lat: 10.793556201747982, lng: 106.73590512970937 },
				{ lat: 10.793900599054743, lng: 106.73705334531742 },
				{ lat: 10.795329843561909, lng: 106.73801749582566 },
				{ lat: 10.796113197164598, lng: 106.73745926753037 },
				{ lat: 10.79709122052834, lng: 106.73593401959573 },
				{ lat: 10.798173285220914, lng: 106.73237510774823 },
				{ lat: 10.799837992521777, lng: 106.73226918775276 },
			];
		case 323:
			return [
				{ lat: 10.79984202860942, lng: 106.73224179398387 },
				{ lat: 10.798145880547144, lng: 106.73237410615144 },
				{ lat: 10.797122480105488, lng: 106.73597591777451 },
				{ lat: 10.795345670012956, lng: 106.73798277302468 },
				{ lat: 10.793906460472337, lng: 106.73702535783741 },
				{ lat: 10.79383678496351, lng: 106.73662195620018 },
				{ lat: 10.793573011795962, lng: 106.73592021364702 },
				{ lat: 10.79247142506746, lng: 106.73570498787741 },
				{ lat: 10.791079930421116, lng: 106.7354435649481 },
				{ lat: 10.790745492979498, lng: 106.73549828137516 },
				{ lat: 10.790572233877318, lng: 106.73572731868677 },
				{ lat: 10.79038384419041, lng: 106.73658480274305 },
				{ lat: 10.789381786131173, lng: 106.73761734463268 },
				{ lat: 10.787957908850666, lng: 106.73932193049117 },
				{ lat: 10.78827923992339, lng: 106.7399821574082 },
				{ lat: 10.787815857685462, lng: 106.74042216897912 },
				{ lat: 10.787517249627559, lng: 106.74090245757702 },
				{ lat: 10.786424341567335, lng: 106.74095717409257 },
				{ lat: 10.786119759935884, lng: 106.74170496520068 },
				{ lat: 10.785409068226018, lng: 106.74199070657015 },
				{ lat: 10.78522392976909, lng: 106.7441976022983 },
				{ lat: 10.785206013139549, lng:  106.74422192070833 },
				{ lat: 10.784393791480188, lng:  106.74509738346904 },
				{ lat: 10.782870869957724, lng:  106.7461369954974 },
				{ lat: 10.781616693503453, lng:  106.74683614978547 },
				{ lat: 10.781074093673238, lng:  106.74788889172477 },
				{ lat: 10.783173704468492, lng:  106.74976663953385 },
				{ lat: 10.785400805964102, lng:  106.75429227109083 },
				{ lat: 10.786361344688864, lng:  106.75444802907104 },
				{ lat: 10.788647924476189, lng:  106.75419708585142 },
				{ lat: 10.792218014274567, lng:  106.75694880837365 },
				{ lat: 10.792388017490323, lng:  106.75880924964468 },
				{ lat: 10.791087490444077, lng:  106.75964861152046 },
				{ lat: 10.789591452363105, lng:  106.76377619526455 },
				{ lat: 10.788962434133014, lng:  106.76434730746871 },
				{ lat: 10.789548951155128, lng:  106.76606064418452 },
				{ lat: 10.78982095866927, lng:  106.76737593289707 },
				{ lat: 10.791121491194936, lng:  106.76828452049456 },
				{ lat: 10.79122349354385, lng:  106.77043051804755 },
				{ lat: 10.791878007562556, lng:  106.77301782939657 },
				{ lat: 10.794232546243581, lng:  106.78029518356713 },
				{ lat: 10.794487549321879, lng:  106.78097878756905 },
				{ lat: 10.810217349756522, lng:  106.77623139050168 },
				{ lat: 10.808585411797056, lng:  106.77401616740686 },
				{ lat: 10.807743940298165, lng:  106.77366138554686 },
				{ lat: 10.808228424161806, lng:  106.76864252060588 },
				{ lat: 10.808099158430444, lng:  106.76696290536606 },
				{ lat: 10.810164581765271, lng:  106.76424579578881 },
				{ lat: 10.813003453240981, lng:  106.76353623209097 },
				{ lat: 10.81443137844867, lng:  106.76205653225976 },
				{ lat: 10.814006401449538, lng:  106.75959036586156 },
				{ lat: 10.812771831104488, lng:  106.75257997094305 },
				{ lat: 10.808411514044968, lng:  106.74845238708956 },
				{ lat: 10.807091366551537, lng:  106.747871591423 },
				{ lat: 10.806057039606781, lng:  106.74661211948415 },
				{ lat: 10.805174816749348, lng:  106.7468185902938 },
				{ lat: 10.805174816749348, lng:  106.7472831496155 },
				{ lat: 10.802862772133574, lng:  106.74829485658277 },
			];
		case 322:
			return [
				{ lat: 10.7773726, lng: 106.7351874 },
				{ lat: 10.7792698, lng: 106.7332992 },
				{ lat: 10.7799654, lng: 106.7310032 },
				{ lat: 10.7800708, lng: 106.7298874 },
				{ lat: 10.7797335, lng: 106.7288574 },
				{ lat: 10.7792487, lng: 106.7283424 },
				{ lat: 10.7790801, lng: 106.7274841 },
				{ lat: 10.778806, lng: 106.7257461 },
				{ lat: 10.7782573, lng: 106.725424 },
				{ lat: 10.7781625, lng: 106.7256707 },
				{ lat: 10.7775723, lng: 106.7258638 },
				{ lat: 10.7769715, lng: 106.7253703 },
				{ lat: 10.7762812, lng: 106.7251557 },
				{ lat: 10.7742154, lng: 106.7248982 },
				{ lat: 10.7726977, lng: 106.725027 },
				{ lat: 10.771728, lng: 106.723525 },
				{ lat: 10.7707115, lng: 106.72124 },
				{ lat: 10.7702103, lng: 106.7192763 },
				{ lat: 10.7693671, lng: 106.7182034 },
				{ lat: 10.769072, lng: 106.7172593 },
				{ lat: 10.7675542, lng: 106.7150706 },
				{ lat: 10.7652354, lng: 106.7147702 },
				{ lat: 10.7635865, lng: 106.7140516 },
				{ lat: 10.7593916, lng: 106.722506 },
				{ lat: 10.7612045, lng: 106.7319044 },
				{ lat: 10.7643665, lng: 106.7340931 },
				{ lat: 10.7683717, lng: 106.7336639 },
				{ lat: 10.7729249, lng: 106.7343935 },
				{ lat: 10.7759603, lng: 106.7350802 },
				{ lat: 10.7773726, lng: 106.7351874 },
			];
		case 330:
			return [
				{ lat: 10.79929054021339, lng: 106.72848194943943 },
				{ lat: 10.802915873639177, lng: 106.74818009192259 },
				{ lat: 10.805192223420875, lng: 106.74719303903127 },
				{ lat: 10.805192223420875, lng: 106.74680680094339 },
				{ lat: 10.806035311552568, lng: 106.74654930888477 },
				{ lat: 10.806920551542417, lng: 106.74766510780539 },
				{ lat: 10.808311637680097, lng: 106.7482230072657 },
				{ lat: 10.81029287044175, lng: 106.73659294928544 },
				{ lat: 10.819187179703928, lng: 106.73255890703399 },
				{ lat: 10.81703739452865, lng: 106.7259070288534 },
				{ lat: 10.808395945723651, lng: 106.72260254743466 },
				{ lat: 10.80380112282729, lng: 106.7261216055689 },
			];
		case 331:
			return [
				{ lat: 10.7776302, lng: 106.7222804 },
				{ lat: 10.7772086, lng: 106.7209929 },
				{ lat: 10.7764919, lng: 106.7207355 },
				{ lat: 10.7762812, lng: 106.7197484 },
				{ lat: 10.7758174, lng: 106.7185897 },
				{ lat: 10.7750164, lng: 106.7174739 },
				{ lat: 10.7745105, lng: 106.7170877 },
				{ lat: 10.7753958, lng: 106.7167014 },
				{ lat: 10.7754801, lng: 106.7161006 },
				{ lat: 10.775438, lng: 106.7156285 },
				{ lat: 10.7747634, lng: 106.7153281 },
				{ lat: 10.7749321, lng: 106.7145556 },
				{ lat: 10.7749321, lng: 106.7132253 },
				{ lat: 10.7750164, lng: 106.7124528 },
				{ lat: 10.7750164, lng: 106.7111653 },
				{ lat: 10.7750164, lng: 106.7101354 },
				{ lat: 10.7748478, lng: 106.7084617 },
				{ lat: 10.7700838, lng: 106.7092341 },
				{ lat: 10.7672591, lng: 106.7100924 },
				{ lat: 10.7651933, lng: 106.7117232 },
				{ lat: 10.763802, lng: 106.7136115 },
				{ lat: 10.7637598, lng: 106.7139977 },
				{ lat: 10.7652354, lng: 106.7147702 },
				{ lat: 10.7675542, lng: 106.7150706 },
				{ lat: 10.769072, lng: 106.7172593 },
				{ lat: 10.7693671, lng: 106.7182034 },
				{ lat: 10.7702103, lng: 106.7192763 },
				{ lat: 10.7705054, lng: 106.7206496 },
				{ lat: 10.7711799, lng: 106.7222804 },
				{ lat: 10.771728, lng: 106.723525 },
				{ lat: 10.7722761, lng: 106.7243403 },
				{ lat: 10.7726977, lng: 106.725027 },
				{ lat: 10.7742154, lng: 106.7248982 },
				{ lat: 10.7762812, lng: 106.7251557 },
				{ lat: 10.7769715, lng: 106.7253703 },
				{ lat: 10.7775723, lng: 106.7258638 },
				{ lat: 10.7781625, lng: 106.7256707 },
				{ lat: 10.7782573, lng: 106.725424 },
				{ lat: 10.7779411, lng: 106.724394 },
				{ lat: 10.7775828, lng: 106.7229456 },
				{ lat: 10.7776302, lng: 106.7222804 },
			];
		default:
			return [];
	}
};

export const  googleGetWardBounds = async (wardId) => {
	let coordinates = getWardCoordinates(wardId);

	if (!window.google || !window.google?.maps || coordinates.length == 0) return null;

	const bounds = new window.google.maps.LatLngBounds();

	coordinates.map(function (coordinate) {
		bounds.extend(new window.google.maps.LatLng(coordinate.lat, coordinate.lng));
	});
	return bounds;
};

export const googleGetBounds = async () => {
	if (!window.google || !window.google?.maps) return null;
	const district2Bounds = new window.google.maps.LatLngBounds();
	d2Bounds.coordinates.map(function (coordinate) {
		district2Bounds.extend(new window.google.maps.LatLng(coordinate[0], coordinate[1]));
	});
	return district2Bounds;
};

export const isPlaceInsidePolygon = (coordinates, {lat, lng}) => {
	const polygon = new google.maps.Polygon({
		paths: coordinates,
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillColor: '#FF0000',
		fillOpacity: 0.35
	});

	const point = new google.maps.LatLng(lat, lng);

	return google.maps.geometry.poly.containsLocation(point, polygon);
}

/**
 * @param {*} username
 * @param {*} passwordEncrypted
 * @returns
 */
export const generateJWT = (username, passwordEncrypted) => {
	var sha256 = function sha256(ascii) {
		function rightRotate(value, amount) {
			return (value >>> amount) | (value << (32 - amount));
		}

		var mathPow = Math.pow;
		var maxWord = mathPow(2, 32);
		var lengthProperty = 'length';
		var i, j; // Used as a counter across the whole file
		var result = '';

		var words = [];
		var asciiBitLength = ascii[lengthProperty] * 8;

		//* caching results is optional - remove/add slash from front of this line to toggle
		// Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
		// (we actually calculate the first 64, but extra values are just ignored)
		var hash = (sha256.h = sha256.h || []);
		// Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
		var k = (sha256.k = sha256.k || []);
		var primeCounter = k[lengthProperty];
		/*/
    var hash = [], k = [];
    var primeCounter = 0;
    //*/

		var isComposite = {};
		for (var candidate = 2; primeCounter < 64; candidate++) {
			if (!isComposite[candidate]) {
				for (i = 0; i < 313; i += candidate) {
					isComposite[i] = candidate;
				}
				hash[primeCounter] = (mathPow(candidate, 0.5) * maxWord) | 0;
				k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
			}
		}

		ascii += '\x80'; // Append Ƈ' bit (plus zero padding)
		while ((ascii[lengthProperty] % 64) - 56) ascii += '\x00'; // More zero padding
		for (i = 0; i < ascii[lengthProperty]; i++) {
			j = ascii.charCodeAt(i);
			if (j >> 8) return; // ASCII check: only accept characters in range 0-255
			words[i >> 2] |= j << (((3 - i) % 4) * 8);
		}
		words[words[lengthProperty]] = (asciiBitLength / maxWord) | 0;
		words[words[lengthProperty]] = asciiBitLength;

		// process each chunk
		for (j = 0; j < words[lengthProperty]; ) {
			var w = words.slice(j, (j += 16)); // The message is expanded into 64 words as part of the iteration
			var oldHash = hash;
			// This is now the undefinedworking hash", often labelled as variables a...g
			// (we have to truncate as well, otherwise extra entries at the end accumulate
			hash = hash.slice(0, 8);

			for (i = 0; i < 64; i++) {
				var i2 = i + j;
				// Expand the message into 64 words
				// Used below if
				var w15 = w[i - 15],
					w2 = w[i - 2];

				// Iterate
				var a = hash[0],
					e = hash[4];
				var temp1 =
					hash[7] +
					(rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) + // S1
					((e & hash[5]) ^ (~e & hash[6])) + // ch
					k[i] +
					// Expand the message schedule if needed
					(w[i] =
						i < 16
							? w[i]
							: (w[i - 16] +
									(rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) + // s0
									w[i - 7] +
									(rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10))) | // s1
							  0);
				// This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
				var temp2 =
					(rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) + // S0
					((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

				hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
				hash[4] = (hash[4] + temp1) | 0;
			}

			for (i = 0; i < 8; i++) {
				hash[i] = (hash[i] + oldHash[i]) | 0;
			}
		}

		for (i = 0; i < 8; i++) {
			for (j = 3; j + 1; j--) {
				var b = (hash[i] >> (j * 8)) & 255;
				result += (b < 16 ? 0 : '') + b.toString(16);
			}
		}
		return result;
	};
	let nonce = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < 11; i++) {
		nonce += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	const created = Math.floor(Date.now() / 1000);
	const passwordDigest = sha256(nonce + created + passwordEncrypted);
	return `/UsernameToken Username="${username}", PasswordDigest="${passwordDigest}", Nonce="${nonce}", Created="${created}"/`;
};

export const getPromotionFuncs = () => {
	const hasPromotion = (product = {}) => {
		if (!Object.keys(product?.availablePromotion || {}).length) return false;

		return product.qty >= product.availablePromotion?.minQuantity
	};

	const hasDiscountPercent = (product = {}) => {
		return (
			hasPromotion(product) &&
			!product.availablePromotion?.bonusQuantity &&
			product.availablePromotion?.discountPercent
		);
	};

	const hasGift = (product = {}) => {
		return hasPromotion(product) && product.availablePromotion?.bonusQuantity;
	};

	const hasSpecialPrice = (product = {}) => {
		return (
			hasPromotion(product) &&
			!product.availablePromotion?.bonusQuantity &&
			product.availablePromotion?.specialPrice
		);
	};

	const getSpecialPrice = (product = {}) => {
		if (!hasSpecialPrice(product)) return 0;

		return product.availablePromotion?.specialPrice;
	};

	const getPriceByDiscountPercent = (product = {}) => {
		if (
			!product?.price ||
			!product?.availablePromotion?.discountPercent
		) return 0;

		const promotionPrice = product.price * product.availablePromotion.discountPercent / 100;
		const newProductPrice = product.price - promotionPrice;

		return newProductPrice;
	};

	return {
		hasPromotion,
		hasDiscountPercent,
		hasGift,
		hasSpecialPrice,

		getSpecialPrice,
		getPriceByDiscountPercent
	}
};

export const hasResponseError = (res = null) => {
	/**
	 * Caution:
	 * Do not set [res = {}] on the function,
	 * if [res = undefined] -> res = {}(wrong)
	 */
	return (
		!res ||
		res.data?.error ||
		res.data?.errors ||
		res.status === 404 ||
		res.errors ||
		res.error ||
		res.status === 405
	);
};

export const scrollAutoSlider = (propSlider, autoTime = 2000) => {
	let timeout;
	let mouseOver = false;
	function clearNextTimeout() {
		clearTimeout(timeout)
	}
	function nextTimeout() {
		clearTimeout(timeout)
		if (mouseOver) return
		timeout = setTimeout(() => {
			propSlider.next()
		}, autoTime)
	}
	propSlider.on("created", () => {
		propSlider.container.addEventListener("mouseover", () => {
			mouseOver = true
			clearNextTimeout()
		})
		propSlider.container.addEventListener("mouseout", () => {
			mouseOver = false
			nextTimeout()
		})
		nextTimeout()
	})
	propSlider.on("dragStarted", clearNextTimeout);
	propSlider.on("animationEnded", nextTimeout);
	propSlider.on("updated", nextTimeout);
};

export const getFullAddress = (item = {}, lang = 'vi') => {
	if (item?.constructor !== Object || !lang) return '';

	const dataArr = [];
	if (item.type?.attributes?.name?.[lang]) {
		dataArr.push(item.type?.attributes?.name?.[lang]);
	}
	if (item.info?.building) {
		dataArr.push(item.info.building);
	}
	if (item.info?.floor) {
		dataArr.push(item.info.floor);
	}
	if (item.info?.street) {
		dataArr.push(item.info.street);
	}
	if (item.ward?.attributes?.fullName?.[lang]) {
		dataArr.push(item.ward.attributes.fullName[lang]);
	}
	if (item.district?.attributes?.fullName?.[lang]) {
		dataArr.push(item.district.attributes.fullName[lang]);
	}
	if (item.city?.attributes?.fullName?.[lang]) {
		dataArr.push(item.city.attributes.fullName[lang]);
	}

	return dataArr.join(', ');
};

<template>
	<AppLayout>
		<router-view></router-view>
		<v-dialog v-model="dialog" width="auto" persistent>
			<v-card max-width="500">
				<template #text>
					<div class="text-center">
						<div class="text-h6 text-center text-uppercase mb-4" style="color: #ffe4ab">
							<div class="mb-3"><v-icon>$mdiAlert</v-icon></div>
							{{ t('common.restaurantClosed') }}
						</div>
						<div>{{ t('common.welcomeMessage') }}</div>
					</div>
				</template>

				<template v-slot:actions>
					<div class="text-center w-full mb-4">
						<v-btn
							class="ms-auto px-6"
							:text="t('common.close')"
							variant="outlined"
							@click="dialog = false"
						></v-btn>
					</div>
				</template>
			</v-card>
		</v-dialog>
	</AppLayout>
</template>

<script setup>
import AppLayout from '@/layouts/App.vue';
import i18n from '@/locale';
import { ref, computed, onBeforeMount, onMounted, watch } from 'vue';
import { api, common } from '@/services';
import { useAuthStore } from '@/stores/auth';
import { useCartStore } from '@/stores/cart';
import { useRoute } from 'vue-router';
const route = useRoute();
const authStore = useAuthStore();
const cartStore = useCartStore();

const orders = computed({
	get: () => cartStore.orders,
	set: (val) => {
		cartStore.setOrders(val);
	},
});
const user = computed(() => authStore.user);
const openTime = computed({
	get: () => cartStore.openTime,
	set: (val) => {
		cartStore.openTime = val
	}
})
const dialog = ref(false);
// const visibility = debounce(function () {
// 	if (document?.visibilityState === 'visible') {
// 		if (location) location.reload();
// 	}
// }, 500);
// cycle & event
watch(
	() => route.path,
	(val) => {
		if (val === '/cart') {
			if (!openTime.value) dialog.value = true;
		}
	}
);
onBeforeMount(() => {
	authStore.setLang(authStore.lang); // set once after mounted
});

onMounted(async () => {
	// visibility tab active call event
	// window.addEventListener('visibilitychange', visibility);
	const openRes = await api.get(common.openTime);
	if (openRes) {
		const { isOpen } = openRes.data;
		if (!isOpen) {
			openTime.value = false;
			dialog.value = true;
		}
	}

	const res = await api.get(common.parameters);
	const data = res.data ||
		res || {
			shipping: 25000,
			vat: 0.1,
		};
	cartStore.setVAT(data.vat);
	cartStore.setShipping(data.shipping);

	// set orders
	if (user.value.id) {
		const res2 = await api.get(
			`${common.orders}?filter[customer]=${user.value.id}&order[createdAt]=desc`
		);
		orders.value = res2.data;
	}

	const meta = document.querySelector('meta[name="description"]');
	if (meta) meta.setAttribute('content', i18n.t('common.baietaMeta'));
	const metaH1 = document.querySelector('#h1-index');
	if (metaH1) metaH1.innerText = i18n.t('common.baietaMetaH1');
});
</script>

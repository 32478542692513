const path = '';
export default {
	// GET
	me: path + '/customer', // attach id in link
	parameters: path + '/parameters', // VAT , shipping
	typeCategories: path + '/type-categories?subResources[categories][filter][status]=1&subResources[categories][order][position]=asc',
	typeAddress: path + '/address-types',
	cities: path + '/cities',
	districts: path + '/districts',
	wards: path + '/wards',
	orders: path + '/orders',
	products: path + '/products', // attach id in link
	productList: (categoryId) => {
		return path + `/products?filter[category]=${categoryId}&filter[status]=1&order[position]=asc`;
	}, // get list
	reorder: (id) => {
		return path + `/orders/${id}/reorder`;
	}, // get reorder
	openTime: path + '/check-time',
	promotions: path + '/promotions',
	coupon: `${path}/coupons/check/{COUPON}/{CUSTOMER_ID}`,

	// POST
	register: path + '/customer',
	login: path + '/customer/auth',
	forgot: path + '/customer/forgot-password',
	updateProfile: path + '/customer', // attach id in link
	forgotPassword: path + '/forgot-password',
	order: path + '/orders',
	addresses: path + '/customer-addresses', // attach id in link (PATCH)
	checkExist: path + '/customer/check-exist',
	orderPayment: path + '/orders/payment-callback',
	submitNewsletter: path + '/newsletters',

	// PUT , PATCH

	// DELETE
	deleteMedia: path + '/me/media', // attach id in link
};
